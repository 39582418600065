#root .card:not(.card-plain):hover {
  transform: unset;
}

#root .table td,
.table th {
  padding: 0.35rem;
}

#root .admin .c-sidebar,
#root .admin .c-sidebar .c-sidebar-minimizer {
  max-width: 160px;
}

@media (min-width: 992px) and (min-width: 992px) {

  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized~.c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized~.c-wrapper {
      margin-left: 56px;
  }
}

@media (min-width: 992px) and (min-width: 992px) {

  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper {
      margin-left: 160px;
  }
}

.status-label-contract {
  div[aria-valuenow="0"] {
      background: #6bd098;
      border: 1px solid #6bd098;
  }

  div[style="left: 0%; width: 0%;"] {
      background: #6bd098;
  }

  span[style="position: absolute; left: 0%;"] span.input-range__label-container {
      color: #6bd098;
  }

  div[aria-valuenow="1"] {
      background: #fbc658;
      border: 1px solid #fbc658;
  }

  div[style="left: 0%; width: 25%;"] {
      background: #fbc658;
  }

  span[style="position: absolute; left: 25%;"] span.input-range__label-container {
      color: #fbc658;
  }

  div[aria-valuenow="2"] {
      background: #51bcda;
      border: 1px solid #51bcda;
  }

  div[style="left: 0%; width: 50%;"] {
      background: #51bcda;
  }

  span[style="position: absolute; left: 50%;"] span.input-range__label-container {
      color: #51bcda;
  }

  div[aria-valuenow="3"] {
      background: #6bd098;
      border: 1px solid #6bd098;
  }

  div[style="left: 0%; width: 75%;"] {
      background: #6bd098;
  }

  span[style="position: absolute; left: 75%;"] span.input-range__label-container {
      color: #6bd098;
  }

  div[aria-valuenow="4"] {
      background: #aaaaaa;
      border: 1px solid #aaaaaa;
  }

  div[style="left: 0%; width: 100%;"] {
      background: #aaaaaa;
  }

  span[style="position: absolute; left: 100%;"] span.input-range__label-container {
      color: #aaaaaa;
  }
}

.status-label {
  div[aria-valuenow="0"] {
      background: #f5593d;
      border: 1px solid #f5593d;
  }

  div[style="left: 0%; width: 0%;"] {
      background: #f5593d;
  }

  span[style="position: absolute; left: 0%;"] span.input-range__label-container {
      color: #f5593d;
  }

  div[aria-valuenow="1"] {
      background: #fbc658;
      border: 1px solid #fbc658;
  }

  div[style="left: 0%; width: 25%;"] {
      background: #fbc658;
  }

  span[style="position: absolute; left: 25%;"] span.input-range__label-container {
      color: #fbc658;
  }

  div[aria-valuenow="2"] {
      background: #51bcda;
      border: 1px solid #51bcda;
  }

  div[style="left: 0%; width: 50%;"] {
      background: #51bcda;
  }

  span[style="position: absolute; left: 50%;"] span.input-range__label-container {
      color: #51bcda;
  }

  div[aria-valuenow="3"] {
      background: #6bd098;
      border: 1px solid #6bd098;
  }

  div[style="left: 0%; width: 75%;"] {
      background: #6bd098;
  }

  span[style="position: absolute; left: 75%;"] span.input-range__label-container {
      color: #6bd098;
  }

  div[aria-valuenow="4"] {
      background: #aaaaaa;
      border: 1px solid #aaaaaa;
  }

  div[style="left: 0%; width: 100%;"] {
      background: #aaaaaa;
  }

  span[style="position: absolute; left: 100%;"] span.input-range__label-container {
      color: #aaaaaa;
  }
}

.table-list {
  margin-top: -20px;
}

.seclect-multi-custom {
  width: 100%;
}

.c-avatar .c-avatar-img {
  width: 100%;
  max-height: 35px;
  object-fit: cover;
  border-radius: unset;
}

.checkbox-table {
  .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: unset;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #ccc;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input~.checkmark {
      background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked~.checkmark {
      background-color: #2196F3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
      content: "";
      position: absolute;
      display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked~.checkmark:after {
      display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  }
}

.scroll-Tab {
  overflow-x: auto !important;
  height: 300px !important
}

.custom-image {
  display: inline-flex;
  border-radius: 2px;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 150px;
  height: 150px;
  padding: 4px;
  box-sizing: border-box;
  object-fit: cover;
}

.coltop-header {
  float: left;

  span {
      font-size: 20px;
      color: royalblue;
  }
}

li.nav-item a {
  font-weight: 600;
}

.status-range {
  padding-top: 30px;
  padding-bottom: 15px;

  span span {
      font-size: 15px;
      bottom: 40%;
  }

  .input-range__slider {
      height: 1.5rem;
      width: 1.5rem;
      margin-top: -0.9rem;
  }

  .input-range__track {
      height: 0.5rem;
  }

  .input-range__label--min {
      display: none;
  }

  .input-range__label--max {
      display: none;
  }
}

.customer-header .h2-modal-header,
.add-content .h2-modal-header {
  padding: 0px 0px 15px 0px;
  margin-top: unset;
}

.card,
.card:focus {
  box-shadow: unset;
}

.row .card-header {
  .btn {
      height: 40px;
  }
}

.btn-check {
  float: left;
}

.ReactTable {
  .btn {
      padding: 5px 15px;
  }
}

.d-flex.justify-content-end {
  float: left;
}

.btn-search {
  float: left;
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}

.btn-back {
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}

.btn-next {
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}

.btn--reload {
  float: left;
}

.search-text {
  width: 65%;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}

.search-month {
  width: 50%;
}

.col-md-4 {
  .search-month {
      width: 40%;
  }
}

.search-month input {
  border-radius: unset;
}

.btn-add {
  margin-right: 15px;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: #007bff;
  padding: 0.8445rem 15px;
}

.card .card-body {
  padding: 5px;
}

.row.card-header {
  padding: 5px 0px 5px 15px;
}

.form-group.row.status-range {
  padding-bottom: unset;
}

.money-more {
  padding-bottom: 5px;
  padding-right: unset;
  border-left: 1px solid #dee2e6;

  textarea {
      margin-bottom: 10px;
  }

  .input-group.mb-3 {
      margin-bottom: unset !important;
  }
}

.staff-change {
  padding: 0px 0px 5px 0px;
  margin: unset;

  div {
      padding: unset;
  }

  .c-avatar-img {
      width: 40px;
      max-height: 40px;
  }
}

.staff-input {
  padding-right: unset;

  .col-md-6,
  .col-md-12 {
      padding-right: unset;
  }
}

.add-staff {
  .custom-image {
      width: 164px;
      height: 164px;
  }

  .h2-modal-header {
      padding: unset;
      margin: 20px
  }
}

.staff-detail {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.modal-show-image {
  img {
      max-width: 300px;
      max-height: 300px;
      object-fit: cover;
  }
}

.modal-show-image-200 {
  img {
      max-width: 200px;
      max-height: 200px;
      object-fit: cover;
  }
}

.comment-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

$colors: hsla(337, 84, 48, 0.75) hsla(160, 50, 48, 0.75) hsla(190, 61, 65, 0.75) hsla(41, 82, 52, 0.75);
$size: 2.5em;
$thickness: 0.5em;

// Calculated variables.
$lat: (
  $size - $thickness) / 2;
$offset: $lat - $thickness;

.loader {
  position: relative;
  width: $size;
  height: $size;
  transform: rotate(165deg
  );

&:before,
&:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: $thickness;
  height: $thickness;
  border-radius: $thickness / 2;
  transform: translate(-50%, -50%);
}

&:before {
  animation: before 2s infinite;
}

&:after {
  animation: after 2s infinite;
}
}

@keyframes before {
  0% {
      width: $thickness;
      box-shadow:
          $lat (
          -$offset) nth($colors, 1),
          (-$lat) $offset nth($colors, 3
      );
}

35% {
  width: $size;
  box-shadow:
      0 (-$offset) nth($colors, 1),
      0 $offset nth($colors, 3);
}

70% {
  width: $thickness;
  box-shadow:
      (-$lat) (-$offset) nth($colors, 1),
      $lat $offset nth($colors, 3);
}

100% {
  box-shadow:
      $lat (
      -$offset) nth($colors, 1),
      (-$lat) $offset nth($colors, 3
  );
}
}

@keyframes after {
  0% {
      height: $thickness;
      box-shadow:
          $offset $lat nth($colors, 2),
          (-$offset) (-$lat) nth($colors, 4);
  }

  35% {
      height: $size;
      box-shadow:
          $offset 0 nth($colors, 2),
          (-$offset) 0 nth($colors, 4);
  }

  70% {
      height: $thickness;
      box-shadow:
          $offset (
          -$lat) nth($colors, 2),
          (-$offset) $lat nth($colors, 4
      );
}

100% {
  box-shadow:
      $offset $lat nth($colors, 2),
      (-$offset) (-$lat) nth($colors, 4);
}
}



/**
* Attempt to center the whole thing!
*/

html,
body {
  height: 100%;
}

.loader {
  position: absolute;
  top: calc(50% - #{$size / 2});
  left: calc(50% - #{$size / 2});
}

.loading {
  // opacity: 0.1;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
}

.noti-new {
  background: lightpink;
  color: white;
}

.react-table select {
  width: 100%;
  height: 27px;
}

.my-tooltip .multi-line {
  text-align: left !important;
  max-width: 400px;
}

.salary {
  span {
      float: right
  }

  input {
      text-align: right;
  }
}

.custom-daytime .react-datepicker-wrapper {
  input {
      width: 90px;
  }
}

.custome-time .react-datepicker-wrapper {
  input {
      width: 100%;
  }
}

.color-red {
  color: red;
}

.color-green {
  color: #1fef7c;
}

.custom-gir {
  display: flex;
  flex-flow: row wrap;

  .custom-item {
      flex-basis: 20%;

      img {
          height: 150px;
          object-fit: cover;
          position: relative;
          width: 150px;
      }
  }
}

.badge.bg-danger.notis {
  font-size: 1em;
  animation: heathing 2s ease infinite;
}

html:not([dir="rtl"]) .c-sidebar-nav-link .badge,
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-toggle .badge.notisnav {
  margin-left: 15px;
  margin-bottom: 10px;
}

.c-sidebar-nav-item .c-active .badge.bg-danger {
  display: none;
}

.badge.bg-danger.notisnav {
  animation: heathing 2s ease infinite;
}

.badge.animation {
  animation: heathing 3s ease infinite;
}

.color-red {
  color: red;
}

.add-staff {
  .react-datepicker-wrapper {
      input {
          height: 40px;
          border: 1px solid #DDDDDD;
          border-radius: 4px;
      }

      input:disabled {
          background-color: #e9ecef;
          opacity: 1;
          border: 1px solid #DDDDDD;
          border-radius: 4px;
      }
  }
}

.html-marquee {
  width: 47%;
  height: 55px;
  overflow: hidden;
  position: relative;

  p {
      color: red;
      font-size: 1.7em;
      line-height: 2em;
  }
}
.qr-code:hover {
  padding-right: 20px;
  padding-top: 35px;
  border-radius: unset;
  transform: scale(10);
}
.package div {
  border-radius: 4px;
  border: 1px solid #f9b115;
  margin-bottom: 5px;
}

.package div:nth-child(1) {
  border: 1px solid #dc3545;
}
.package div:nth-child(2) {
  border: 1px solid #f9b115;
}
.package div:nth-child(3) {
  border: 1px solid #2eb85c;
}
.hourly-day {
  padding-right: 10px;
}
.package div:nth-child(1).box-shadow {
  box-shadow: 2px 2px #dc3545;
}
.package div:nth-child(2).box-shadow {
  box-shadow: 2px 2px #f9b115;
}
.package div:nth-child(3).box-shadow {
  box-shadow: 2px 2px #2eb85c;
}
.color-blue {
  background-color: #6df2f5;
}
.c-sidebar-nav-dropdown:has(.c-sidebar-nav-item a.c-sidebar-nav-link.c-active) {
  .c-sidebar-nav-dropdown-toggle {
      color: #fff;
      background: #007bff;
      padding: 0.8445rem 15px;
      .c-sidebar-nav-icon {
          color: #fff;
      }
  }
}
.padding-unset {
  button {
      padding: 5px 10px;
  }
  .border-left {
      border-left: 1px solid rgba(0,0,0,.125);
  }
  .col-md-10 a, .col-md-8 a {
      color: rgb(81, 188, 218);
  }
  .col-md-3 .row {
      padding-left: 15px;
      padding-bottom: 15px;
  }
  h3 {
      padding-bottom: 20px;
      text-align: center;
      color: #007bff;
  }
}
@media (min-width: 576px) {
.modal-post-group .modal-sm {
  max-width: 500px;
}
.modal-show-post .modal-sm {
  max-width: 700px;
}
}
.modal.overflow-auto.fade.modal-customer.modal-show-post.show.d-block {
overflow: unset!important;
}
.modal-show-post.view-image-full {
.modal-dialog.modal-sm {
  flex-wrap: wrap;
  display: flex;
  max-width: 100%;
  margin-top: 0px;
  .modal-content {
    flex-wrap: wrap;
    display: flex;
    border-radius: unset;
    margin: unset;
    float: right;
    max-width: 100%;
    .customer-header {
      height: 93vh;
    }
    .col-md-8.view-media {
      background-color: black;
      display: flex;
      flex-wrap: wrap;
      .back, .next {
        background: #e4e6eb;
        border-color: #e4e6eb;
        color: black;
        height: 50px;
        display: block;
        margin: auto;
        vertical-align: middle;
        border-radius: 40px;
        font-size: 24px;
      }
      img {
        display: block;
        margin: auto;
        width: 80%;
        vertical-align: middle;
      }
    }
  }
}
}
.modal-show-post {
.infor-hidden {
  display: none;
  position: absolute;
  background: white;
  top: -130px;
  padding: 20px;
  left: 20px;
  z-index: 99;
  .c-avatar {
    width: 60px!important;
    height: 60px!important;
    img {
      max-height: 60px!important;
    }
  }
  .name {
    b {font-size: 20px;}
    background-color: white!important;
    border-color: white!important;
  }
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.posts-header .infor-hidden {
  top: 0px;
  left: 200px;
}
.btn-picker {
  position: absolute;
  right: 40px;
  border-color: #f0f2f5;
  background: #f0f2f5;
  color: #768192;
  font-size: 18px;
  padding-bottom: 0px;
  padding-top: 2px;
  z-index: 99;
}
.btn-camera {
  position: absolute;
  right: 80px;
  border-color: #f0f2f5;
  background: #f0f2f5;
  color: #768192;
  font-size: 18px;
  padding-bottom: 0px;
  padding-top: 7px;
  z-index: 99;
  border: unset;
}
.emoji-picker {
  position: relative;
  .emoji-picker-picker {
    z-index: 99;
    position: absolute;
    right: 0px;
    top: -324px;
  }
}
.pl-16 {
  padding-left: 32px;
  padding-right: 16px;
  padding-bottom: 10px;
  b {
    margin-left: 50px;
    font-size: 12px;
  }
  .c-avatar {
    cursor: pointer;
    width: 40px;
    height: 40px;
    .c-avatar-img {
      max-height: 40px;
      border-radius: 50px;
    }
  }
  input {
    cursor: pointer;
  }
  .search-text {
    width: 90%;
    margin-left: 10px;
    border-radius: 1.5rem;
    background: #f0f2f5;
    border-color: #f0f2f5;
  }
  .search-text:hover {
    background: #e4e6eb;
    border-color: #e4e6eb;
  }
}
.customer-header {
  height: 87vh;
}
.modal-dialog.modal-sm {
  padding-top: 0px;
  padding-bottom: 16px;
}
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}
.card:not(.card-plain):hover {
  box-shadow: none;
}
.header-text {
  border-bottom: 1px solid #DDDDDD;
  position: inherit;
}
.h2-modal-header.text-center {
  font-size: 20px;
  font-weight: 600;
  padding-top: 16px;
}
.close.btn {
  background: #e4e6eb;
  border-radius: 1.5rem;
  padding: 0px 10px;
  margin-right: 12px;
  margin-top: 12px;
  float: right;
  i {
    color: #768192;
  }
}
.group-body {
  height: 80vh;
  .posts.card {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
    .btn-upload {
      border-top: 1px solid #dee2e6!important;
      margin: 0 auto;
      padding: 8px 0px 10px;
      .col-md-4 {
        height: 40px;
      }
      .fiml, .like, .vote, .img {
        width: 100%;
        height: 100%;
        background: #ffffff;
        border-color: #ffffff;
        color: #65676B;
        text-transform: none;
        font-size: 15px;
      }
      .fiml {
        cursor: no-drop;
      }
      .like:hover, .img:hover, .vote:hover {
        background: #f0f2f5!important;
        border-color: #f0f2f5!important;
      }
    }
    .btn-upload {
      .like-me {
        color: #1b74e4;
        img {
          width: 15px!important;
        }
      }
      .fiml.btn {
        img {
          width: 25px;
        }
      }
      .like.btn {
        img {
          width: 25px;
        }
      }
      .vote.btn {
        img {
          width: 20px;
        }
      }
      border-bottom: 1px solid #dee2e6 !important;
      padding: 2px 0px 2px;
      margin-bottom: 10px;
    }
    .more-comment {
      font-weight: 600;
      padding: 10px 0px;
    }
    .more-feedback {
      font-weight: 600;
      padding: 5px 0px;
      margin-left: 50px;
    }
    padding: unset;
    .posts-header {
      padding: 12px 16px 0px;
      margin: 0 0 12px;
    }
    .name {
      margin-left: 10px;
      b {
        font-weight: 600;
      }
    }
    .col-md-12.row.pl-16 {
      margin-left: 5px;
      padding-right: 0px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .pl-16 {
      padding-left: 32px;
      padding-right: 16px;
      padding-bottom: 4px;
      b {
        margin-left: 50px;
        font-size: 12px;
      }
    }
    .three-dots {
      button {
        background: #ffffff;
        border-color: #ffffff;
        color: #65676B;
        font-size: 25px;
        padding: 0px;
        margin-top: -10px;
      }
    }
    .comments.childs {
      padding-left: 54px;
      .c-avatar {
        width: 24px;
        height: 24px;
      }
    }
    .comments {
      padding-left: 32px;
      padding-right: 16px;
      .name {
        padding: 8px 12px;
        background-color: #f0f2f5;
        border-color: #f0f2f5;
        border-radius: 1.5rem;
        max-width: 550px;
      }
      .child-comment {
        .like, .comment {
          font-weight: 600;
        }
        font-size: 12px;
        padding-left: 50px;
        .like-me {
          color: #1b74e4;
          img {
            width: 15px!important;
          }
        }
      }
    }
    .comments .name {
      .statusOnlineDiv:has(div.row) {
        position: sticky;
      }
      .statusOnlineDiv {
        div.row {
          padding: 2px 2px 0px 2px;
          background: white;
          border-radius: 10px;
          position: absolute;
          right: -20px;
          bottom: -5px;
          font-size: 13px;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
.posts.card {
  .c-avatar:hover ~ .infor-hidden {
    display: block;
  }
  .name:hover  ~ .infor-hidden {
    display: block;
  }
  .image {
    img:nth-child(1) {
      width: 100%;
    }
    img {
      border-radius: unset;
      width: 50%;
    }
  }
  .c-avatar {
    cursor: pointer;
    width: 40px;
    height: 40px;
    .c-avatar-img {
      max-height: 40px;
      border-radius: 50px;
    }
  }
  input {
    cursor: pointer;
  }
  .search-text {
    width: 90%;
    margin-left: 10px;
    border-radius: 1.5rem;
    background: #f0f2f5;
    border-color: #f0f2f5;
  }
  .search-text:hover {
    background: #e4e6eb;
    border-color: #e4e6eb;
  }
  .content {
    padding: 4px 16px 16px;
    b.underline {
      font-weight: 600;
    }
  }
  .image {
    text-align: center;
    background-color: #e8e8ea;
  }
  .like-comment {
    padding: 10px 0px;
    margin: 0px 16px;
    .comment {
      text-align: right;
      float: right;
    }
    .like {
      img {
        width: 20px;
      }
    }
  }
}
.card {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  p {
    font-weight: 600;
  }
}
}
.upload-image >img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 450px;
}
.modal-post-group {
.upload-image {
  .custom-gir {
    width: 100%;
  }
}
.emoji-picker {
  .btn-picker {
    border-color: white;
    float: right;
    background: white;
    color: #768192;
    font-size: 30px;
    padding: 0px 20px 0px 10px;
  }
  .emoji-picker-picker {
    z-index: 99;
    position: absolute;
    right: 60px
  }
}
.custom-image-post {
  img {
    max-width: 450px;
  }
}
.header-text {
  border-bottom: 1px solid #DDDDDD;
  position: inherit;
}
.h2-modal-header.text-center {
  font-size: 20px;
  font-weight: 600;
  padding-top: 16px;
}
.close.btn {
  background: #e4e6eb;
  border-radius: 1.5rem;
  padding: 0px 10px;
  margin-right: 12px;
  margin-top: 12px;
  float: right;
  i {
    color: #768192;
  }
}
.incognito {
  margin: 12px 5px 0px;
  background: #f0f2f5;
  border-color: #f0f2f5;
  border-radius: 10px;
  padding: 10px 10px 5px 15px;
}
.c-avatar-img {
  max-height: 40px;
  border-radius: 50px;
}
.name {
  margin-left: 10px;
  b {
    font-weight: 600;
  }
}
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}
.posts-header {
  padding: 16px 16px 0px;
}
.btn-xem.btn {
  width: 100%;
  background-color: #1b74e4;
  border-color: #1b74e4;
  color: white;
  margin: 16px 0px 16px 0px;
  border-radius: 6px;
  height: 36px;
  text-transform: none;
}
textarea {
  border: unset;
  font-size: 16px;
}
textarea.form-control:focus {
  border: unset;
}
.add-img {
  text-align: center;
  margin-top: 16px;
  height: 245px;
  width: 100%;
  background: none;
  color: black;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  text-transform: none;
  font-size: 17px;
  b {
    font-size: 12px;
    font-weight: unset;
  }
}
.c-switch-opposite-success .c-switch-input:checked + .c-switch-slider {
  border-color: #1b74e4;
}
.c-switch-opposite-success .c-switch-input:checked + .c-switch-slider {
  background-color: #1b74e4;
}
.c-switch-opposite-success .c-switch-input:checked + .c-switch-slider::before {
  background-color: white;
  border-color: white;
}
.c-switch-opposite-success .c-switch-input:checked + .c-switch-slider::after {
  color: #1b74e4;
}
.c-switch-label .c-switch-slider::after {
  color: #d8dbe0;
}
.c-switch-slider {
  background-color: #d8dbe0;
}
.c-avatar {
  height: 40px;
  width: 40px;
}
}
.custom-image-post {
max-width: 100px !important;
}
.custom-comment-img {
margin-left: 0px;
img {
  max-width: 50px !important;
}
}
.group-chat {
.btn-camera {
  position: absolute;
  right: 80px;
  border-color: #f0f2f5;
  background: #f0f2f5;
  color: #768192;
  font-size: 18px;
  padding-bottom: 0px;
  padding-top: 7px;
  z-index: 99;
  border: unset;
}
.infor-hidden {
  display: none;
  position: absolute;
  background: white;
  top: -140px;
  padding: 20px;
  left: -40px;
  z-index: 99;
  .c-avatar {
    width: 60px!important;
    height: 60px!important;
    img {
      max-height: 60px!important;
    }
  }
  .name {
    b {font-size: 20px;}
    background-color: white!important;
    border-color: white!important;
  }
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.btn-picker {
  position: absolute;
  right: 40px;
  border-color: #f0f2f5;
  background: #f0f2f5;
  color: #768192;
  font-size: 18px;
  padding-bottom: 0px;
  padding-top: 4px;
  z-index: 99;
}
.emoji-picker {
  position: relative;
  .emoji-picker-picker {
    z-index: 99;
    position: absolute;
    right: 0px;
    top: -324px;
  }
}
.mt-3.card {
  position: absolute;
  width: 300px;
  right: 0px;
  top: 30px;
  background: #FFFFFF;
  z-index: 101;
  box-shadow: 6px 6px 10px 6px rgba(0, 0, 0, 0.15);
}
.member {
  padding: 16px;
  > div:nth-child(1) {
    font-size: 17px;
    > b {
      font-size: 15px;
      color: #65676B;
      font-weight: 500;
    }
  }
}
.view-media {
  .component-six-main-item {
    padding-top: 0px;
    margin: -5px;
    .item-post {
      flex-basis: 50%;
      .item-post-div {
        margin: 4px;
      }
      .thumbnailWrapper .thumbnailImg {
        height: 200px;
      }
    }
    .item-post:nth-child(1) .thumbnailWrapper .thumbnailImg img {
      border-radius: 0.75rem 0rem 0rem 0rem;
    }
    .item-post:nth-child(2) .thumbnailWrapper .thumbnailImg img {
      border-radius: 0rem 0.75rem 0rem 0rem;
    }
    .item-post:nth-child(3) .thumbnailWrapper .thumbnailImg img {
      border-radius: 0rem 0rem 0rem 0.75rem;
    }
    .item-post:nth-child(4) .thumbnailWrapper .thumbnailImg img {
      border-radius: 0rem 0rem 0.75rem 0rem;
    }
  }
}
.component-six-main-item {
  padding-top: 16px;
  display: flex;
  flex-wrap: wrap;
  .item-post {
    flex-basis: calc(100% / 6);
    .item-post-div {
      margin: 5px;
      display: block;
      height: 100%;
      position: relative;
      .thumbnailWrapper {
        display: flex;
        justify-content: center;
        position: relative;
      }
    }
    .thumbnailWrapper .thumbnailImg {
      background-size: cover !important;
      display: inline-block;
      height: 165px;
      position: relative;
      width: 100%;
      img {
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
        border-radius: unset;
      }
    }
  }
}
.header-media {
  .name { font-size: 20px; }
  .ml-auto.mr-1 button {
    border-radius: 0.5rem;
    background: #ffffff;
    border-color: #ffffff;
    color: #1877F2;
    text-transform: none;
    font-size: 15px;
  }
  .btn:hover, .ml-auto.mr-1 button:hover {
    background: #f0f2f5;
    border-color: #f0f2f5;
  }
  .btn-menu button {
    border-radius: 0.5rem;
    background: #ffffff;
    border-color: #ffffff;
    color: #65676B;
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
  }
  .btn-menu button.btn.active {
    border-radius: unset;
    background: #ffffff;
    border-color: #ffffff;
    color: #65676B;
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    color: #24853c;
    border-bottom: 0.2rem solid #24853c;
  }
}
.col-md-4:has(.seclect-custom) {
  padding: 0px 0px 16px 0px;
  .seclect-custom {
    height: 40px;
    background-color: unset;
    border: 1px solid #e5ede5;
    font-size: 16px;
    font-weight: 600;
    color: #00550f;
  }
}
.btn.active:focus {
  color: #24853c!important;
  border-color: #f7f7f7!important;
  border-bottom: 0.2rem solid #24853c!important;
  background: #f7f7f7!important;
}
.card:not(.card-plain):hover {
  box-shadow: none;
}
.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}
.card .card-body {
  padding: 0px;
  .write.card {
    padding: 12px 16px 0px 16px;
  }
}
.border-left {
  b {
    font-weight: 600;
  }
}
.group-body {
  max-width: 1218px;
  margin: 0 auto;
  border-radius: unset;
  padding: 16px 16px 0px;
  .group-title {
    div {
      padding: 12px 6px;
      margin: -6px;
    }
  }
  .post {
    flex: 0 0 56.5%;
    max-width: 56.5%;
    padding: unset;
    margin: 8px;
    .btn-upload {
      border-top: 1px solid #dee2e6!important;
      margin: 0 auto;
      padding: 8px 0px 10px;
      .col-md-4 {
        height: 40px;
      }
      .fiml, .like, .vote, .img {
        width: 100%;
        height: 100%;
        background: #ffffff;
        border-color: #ffffff;
        color: #65676B;
        text-transform: none;
        font-size: 15px;
      }
      .fiml {
        cursor: no-drop;
      }
      .like:hover, .img:hover, .vote:hover {
        background: #f0f2f5!important;
        border-color: #f0f2f5!important;
      }
    }
    .like-comment {
      padding: 10px 0px;
      margin: 0px 16px;
      .comment {
        text-align: right;
        float: right;
      }
      .like {
        img {
          width: 20px;
        }
      }
    }
    .image {
      text-align: center;
      background-color: #e8e8ea;
    }
    .posts {
      .image {
        img:nth-child(1) {
          width: 100%;
        }
        img {
          border-radius: unset;
          width: 50%;
        }
      }
      .btn-upload {
        .like-me {
          color: #1b74e4;
          img {
            width: 15px!important;
          }
        }
        .fiml.btn {
          img {
            width: 25px;
          }
        }
        .like.btn {
          img {
            width: 25px;
          }
        }
        .vote.btn {
          img {
            width: 20px;
          }
        }
        border-bottom: 1px solid #dee2e6 !important;
        padding: 2px 0px 2px;
        margin-bottom: 10px;
      }
      .more-comment {
        font-weight: 600;
        padding: 5px 16px 10px;
      }
      .more-feedback {
        font-weight: 600;
        padding: 5px 0px;
        margin-left: 50px;
      }
      padding: unset;
      .posts-header {
        padding: 12px 16px 0px;
        margin: 0 0 12px;
      }
      .name {
        margin-left: 10px;
        b {
          font-weight: 600;
        }
      }
      .comments .name {
        .statusOnlineDiv:has(div.row) {
          position: sticky;
        }
        .statusOnlineDiv {
          div.row {
            padding: 2px 2px 0px 2px;
            background: white;
            border-radius: 10px;
            position: absolute;
            right: -20px;
            bottom: -5px;
            font-size: 13px;
            img {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
      .col-md-12.row.pl-16 {
        margin-left: 20px;
        padding-right: 0px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .pl-16 {
        padding-left: 32px;
        padding-right: 16px;
        padding-bottom: 4px;
        b {
          margin-left: 50px;
          font-size: 12px;
        }
      }
      .three-dots {
        button {
          background: #ffffff;
          border-color: #ffffff;
          color: #65676B;
          font-size: 25px;
          padding: 0px;
          margin-top: -10px;
        }
        .mt-3.card {
          button {
            height: 45px;
            width: 100%;
            text-transform: none;
            font-size: 15px;
          }
          button:hover {
            background: #f0f2f5;
            border-color: #f0f2f5;
          }
        }
        .mt-3.card:hover {
          box-shadow: 6px 6px 10px 6px rgba(0, 0, 0, 0.15);
        }
      }
      .comments {
        padding-left: 32px;
        padding-right: 16px;
        .name {
          padding: 8px 12px;
          background-color: #f0f2f5;
          border-color: #f0f2f5;
          border-radius: 1.5rem;
          max-width: 550px;
        }
        .child-comment {
          .like, .comment {
            font-weight: 600;
          }
          .like-me {
            color: #1b74e4;
          }
          font-size: 12px;
          padding-left: 50px;
        }
      }
    }
    .content {
      padding: 4px 16px 16px;
      b.underline {
        font-weight: 600;
      }
    }
  }
  .border-left {
    flex: 0 0 40.5%;
    max-width: 40.5%;
    padding: unset;
    margin: 8px;
    border-left: unset!important
  }
}
.btn-xem {
  background: #e4e6eb;
  border-color: #e4e6eb;
  color: black;
  text-transform: none;
  font-size: 15px;
  border-radius: 0.5rem;
  margin-top: 10px;
}
.btn-xem:hover {
  background: #66615b2e;
  border-color: #66615b00;
}
.cover {
  height: 400px;
  object-fit: cover;
  width: 100%;
}
.card {
  max-width: 1292px;
  margin: 0 auto;
  border-radius: unset;
}
.row.card-header {
  padding: 0px 22px 0px 22px;
}
.react-table.padding-unset.card-body {
  background: #e5ede5;
}
.card-body {
  .pl-16 {
    padding-left: 16px;
    padding-bottom: 16px;
  }
  .c-avatar:hover ~ .infor-hidden {
    display: block;
  }
  .name:hover  ~ .infor-hidden {
    display: block;
  }
  .post {
    .c-avatar {
      cursor: pointer;
      width: 40px;
      height: 40px;
      .c-avatar-img {
        max-height: 40px;
        border-radius: 50px;
      }
    }
    input {
      cursor: pointer;
    }
    .search-text {
      width: 90%;
      margin-left: 10px;
      border-radius: 1.5rem;
      background: #f0f2f5;
      border-color: #f0f2f5;
    }
    .search-text:hover {
      background: #e4e6eb;
      border-color: #e4e6eb;
    }
  }
  .card {
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    p {
      font-weight: 600;
    }
  }
}
}
.group-header {
img {
  cursor: pointer;
  border-radius: 0 0 12px 12px;
}
h2:hover {
  text-decoration: underline;
}
h2 {
  cursor: pointer;
}
.avatar {
  padding: 20px 20px;
  .c-avatar {
    border: 1px solid #f7f7f7 !important;
    margin-left: -6px;
    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 50em;
    width: 36px;
    height: 36px;
    font-size: 14.4px;
    .c-avatar-img {
      width: 36px;
      height: 36px;
      width: 100%;
      max-height: 36px;
      object-fit: cover;
      border-radius: unset;
      border-radius: 50px;
    }
  }
}
}
.group-menu {
border-top: 1px solid #dee2e6;
padding: 10px 20px 0px;
input {
  border-radius: 1.5rem;
}
.btn-search, .btn-reload {
  border-radius: 0.5rem;
  margin-left: 10px;
  background: #E4E6EB;
  border-color: #E4E6EB;
  color: black;
  padding: 0px 16px;
  i {
    font-size: 16px;
    font-weight: 400;
  }
}
.btn-search:hover, .btn-reload:hover {
  background: #66615b2e;
  border-color: #66615b00;
}
.col-md-7 {
  .btn {
    border-radius: 0.5rem;
    background: #f7f7f7;
    border-color: #f7f7f7;
    color: #65676B;
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 40px;
  }
  .btn:hover {
    background: #f0f2f5;
    border-color: #f0f2f5;
  }
  .btn.active {
    border-radius: unset;
    padding-bottom: 40px;
    background: #f7f7f7;
    border-color: #f7f7f7;
    color: #65676B;
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    color: #24853c;
    border-bottom: 0.2rem solid #24853c;
  }
}
}

@media (max-width: 1155px) {
.group-chat .group-body .border-left {
  flex: 0 0 100%;
  max-width: 100%;
}
.group-chat .group-body .post {
  flex: 0 0 100%;
  max-width: 100%;
}
.group-chat .group-body {
  max-width: 800px;
}
}

.comment-hidden {
max-width: 100px;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
overflow: hidden;
}
.ck-editor__editable_inline {
min-height: 400px;
}
.add-post-timviecnha {
.custom-gir {
  display: unset;
}
.upload-image {
  text-align: -webkit-center;
}
}
.creat_group_facebook {
button.btn-reload {
  padding: 0 18px;
}
.ReactTable .-pagination .-center {
  flex: 2.5 1;
}
input[type=checkbox], input[type=radio] {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
}
.add-post-facebook {
input[type=checkbox], input[type=radio] {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
label.checkbox {
  padding: 10px 10px;
  cursor: pointer;
  text-align: left;
}
.btn-reload {
  margin-left: 8px;
}
.chatgpt {
  margin-left: 16px;
  padding: 1px 15px;
  background-color: #15a27f;
  border-color: #15a27f;
  img {
    height: 45px;
    width: 45px;
  }
}
.upload-image > img {
  max-width: 150px;
}
}
.kep-login-facebook.metro {
border: 2px solid #ffffff;
width: 228px;
font-weight: 500;
font-size: 15px;
text-transform: unset;
padding: 14px 14px 14px 0px;
height: 54px;
i {
  margin-right: 20px;
  margin-left: 0px;
}
}
.html-marquee p.text-turn {
font-size: 1.5em;
line-height: 2em;
margin-bottom: 0px;
}

.html-marquee p.text-sologan {
color: green;
font-size: 1.5em;
line-height: 0em;
margin-bottom: 0px;
}
.badge-soft-warning .fc-event-title.fc-sticky {
background-color: red;
}
.image-star img {
max-height: 300px;
}
.staff-star {
.dropdown-menu.show {
  overflow-y: auto;
  height: 90vh;
  min-width: 30rem;
  a.dropdown-item {
    display: flex;
    padding: 10px;
    margin-left: 0px;
    .image-star img {
      max-width: 100%;
    }
  }
}
span.badge {
  margin-top: -10px !important;
  cursor: pointer;
  padding: 0;
  background-color: white !important;
  i {
    color: #f7765f;
    font-size: 20px;
    width: 0px;
  }
}
}

#root .admin .c-sidebar.list-chat.c-sidebar-minimized {
max-width: 90vh;
background: white;
border-left: 1px solid #ccccd0;
.list-chat-item {
  list-style: none;
  padding: 5px 5px 0px 3px;
  .avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
  }
  .live, .name, .noti {
    display: none;
  }
  .noti.active {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid white;
    margin-left: 34px;
    margin-top: 34px;
  }
}
.list-chat-item.active {
  color: #050505;
  background-color: #abd0f4;
}
}
#root .admin .c-sidebar.list-chat {
.badge.bg-danger.notisnav {
  display: none;
  font-size: 16px;
  height: 30px;
  width: 30px;
  padding: 8px 0px 0px 0px;
  right: 0;
  margin-top: 25px;
}
.badge.bg-danger.notisnav.active {
  display: block;
}
max-width: 90vh;
background: white;
border-left: 1px solid #ccccd0;
color: black;
.list-user-chat {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}
.list-chat-item {
  list-style: none;
  display: flex;
  padding: 5px;
  .avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
  }
  .live, .name, .noti {
    display: block;
  }
  .name {
    margin-left: 3px;
    font-size: 16px;
    max-width: 180px;
    .mess {
      font-size: 12px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
  .noti {
    display: none;
    height: 12px;
    width: 12px;
    background: #0866ff;
    border-radius: 50%;
    margin: 12px 0px;
    margin-left: auto;
  }
  .noti.active {
    display: block;
  }
}
.list-chat-item.active {
  color: #050505;
  background-color: #abd0f4;
}
}
#root .admin .c-sidebar.list-chat .c-sidebar-minimizer {
max-width: 200px;
bottom: 0;
position: absolute;
}
#chatBox {
position: absolute;
bottom: 0;
height: 320px;
width: 320px;
right: 0;
z-index: 999;
background: white;
border: 1px solid #ccc;
flex-direction: column;
.chat-header {
  background: #ffffff;
  padding: 5px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  height: 50px;
  .first {
    position: absolute;
    top: 10px;
    .avatar {
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }
  }
  .live {
    background: #3cc842;
    border: 2px solid white;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    position: absolute;
    bottom: 0px;
    top: 20px;
    left: 22px;
  }
  .name {
    margin-left: 40px;
    span {
      font-size: 16px;
      font-weight: 500;
    }
    p {
      font-size: 12px;
    }
  }
  .end {
    position: absolute;
    top: 10px;
    right: 5px;
    button.btn-close {
      border-radius: 50%;
      background: white;
      color: #bcc0c4;
      border: unset;
      width: 27px;
      height: 27px;
      font-size: 20px;
      position: absolute;
      top: 0px;
      right: 0;
    }
    button:focus {
      outline: unset;
    }
    button.btn {
      border-radius: 50%;
      color: #bcc0c4;
      border: unset;
      width: 33px;
      height: 33px;
      padding: 0px;
    }
    a:hover {
      text-decoration: unset;
    }
    a {
      display: flex;
      margin-right: 32px;
      button {
        text-transform: unset;
        background-color: white;
        span {
          font-size: 16px;
        }
      }
    }
  }
}
.chat-body {
  height: 215px;
  .messages {
    height: 215px;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    .timestamp {
      display: none;
    }
    .timestamp.active {
      display: block;
    }
    .user {
      line-height: 23px;
      display: inline-block;
      width: 100%;
      padding-right: 5px;
      text-align: left;
      color: #333;
      display: flex;
      align-items: flex-end;
      img {
        border-radius: 50%;
        float: left;
        width: 32px;
        height: 32px;
        margin: 2px 0px 5px 5px;
      }
      span {
        color: #CCC;
        font-size: 12px;
        padding-left: 10px;
      }
      .text div {
        border-radius: 20px;
        background: #f0f0f0;
        display: inline-block;
        padding: 5px 10px 5px 10px;
        margin: 5px;
      }
    }
    .me {
      background: white;
      line-height: 23px;
      display: inline-block;
      width: 100%;
      padding-right: 5px;
      text-align: right;
      span {
        color: #CCC;
        font-size: 12px;
      }
      .text div {
        border-radius: 20px;
        background: #6bd098;
        color: white;
        display: inline-block;
        padding: 5px 10px 5px 10px;
        margin: 5px;
      }
    }
  }
}
.chat-footer {
  bottom: 0;
  position: relative;
  padding: 5px 10px 10px 9px;
  input {
    width: 235px;
  }
}
}
@media (min-width: 992px) {
#root .admin .c-sidebar.list-chat.c-sidebar-minimized ~ #chatBox {
  margin-right: 50px;
}
#root .admin .c-sidebar.list-chat ~ #chatBox {
  margin-right: 250px;
}
html:not([dir="rtl"]) .c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed.c-sidebar-right.list-chat:not(.c-sidebar-minimized) ~ .c-wrapper,
html:not([dir="rtl"]) .c-sidebar.c-sidebar-dark.c-sidebar-show.c-sidebar-fixed.c-sidebar-right.c-sidebar-fixed.list-chat:not(.c-sidebar-minimized) ~ .c-wrapper {
  margin-right: 200px!important;
}
html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper {
  margin-right: 50px!important;
}
}
.highlight {
background-color: red;
color: white;
}
.seclect-multi-custom.z-index {
  z-index: 999;
}
.modal-sm {
  max-width: 320px;
}
.add-cccd {
  .col-6 {
    padding: unset;
  }
  .btn-success, .btn-warning {
    margin-bottom: 15px;
  }
  img {
    display: inline-flex;
    border-radius: 2px;
    margin-bottom: 8px;
    margin-right: 15px;
    width: 140px;
    height: 140px;
    padding: 0px;
    box-sizing: border-box;
    object-fit: cover;
  }
}
